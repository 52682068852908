.mapboxgl-ctrl-geocoder {
  padding: 4px;
}
.mapboxgl-ctrl-geocoder--icon-search {
  left: 8px;
  top: 10px;
}
.mapboxgl-ctrl-geocoder--icon-close {
  top: 2px;
  right: 12px;
  position: absolute;
}
.mapboxgl-ctrl-geocoder .suggestions {
  position: relative;
  padding: 4px;
  box-shadow: none;
}
.suggestions:first-child {
  border-radius: 18px 18px 0px 0px;
}
.suggestions:last-child {
  border-radius: 0px 0px 18px 18px;
}
.mapboxgl-ctrl {
  border-radius: 18px;
  box-shadow: 0px 3px 6px #00000069;
  margin: 0 !important;
}
.mapboxgl-ctrl-geocoder--input {
  border-radius: 18px;
  height: 32px;
}

.MuiInput-underline {
  border-bottom: none;
}
.MuiInput-underline::before {
  border-bottom: none;
}
.MuiInput-underline:hover {
  border-bottom: none;
}
.MuiInput-underline:hover:not(.Mui-disabled)::before {
  border-bottom: none;
}
.MuiInput-underline::after {
  border-bottom: none;
}
.MuiSelect-icon {
  padding-right: 8px;
  color: white;
}
.mapboxgl-ctrl-top-left {
  top: 20px;
  left: 20px;
}
