li .edit-name-button {
  display: none;
}
li:hover .edit-name-button {
  display: block;
}

div.accordionSummary .edit-group-name-button {
  display: none;
}
div.accordionSummary:hover .edit-group-name-button {
  display: block;
}

.accordionSummary div:nth-child(1) {
  align-items: center;
  margin: 0px !important;
}
