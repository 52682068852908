.margin {
    margin: 0.3rem;
}

.margin .makeStyles-lineItemButton-31 {
    padding: 14px 24px !important;
    font-size: 16px !important;
}

.marginTop {
    margin: 0.5rem;
}