.dr-root {
  right: 0;
  position: absolute;
  z-index: 2;
  width: 250px;
  background-color: white;
  height: 100%;
  border-left: 1px solid #707070;
  overflow: scroll;
  /* padding: 16px; */

}

.scene {
  background: red;
  width: 218px;
  height: 200px;
  margin-bottom: 12px;
}

.dr-input {
  display: inline;
  padding: 0px;
  max-width: 50px;
  color: black;
}

.dr-input-label {
  font-weight: 700;
}

/* color picker css must be overridden here since not a standard material ui component */
.MuiOutlinedInput-notchedOutline {
  height: 44px;
  border-radius: 8px;
  border: none;
  box-shadow: 0px 3px 6px #00000069;
}
